









































































import Error from '@/components/Error.vue';
import { BaseComponent, Component } from '@/components/BaseComponent';
import { TidSDKUIParams, TidSDKAuthParams } from '@/types/tidSdk';

@Component({
  components: {
    Error,
  },
})
export default class LoginPage extends BaseComponent {
  readonly extAuth2ContainerId = 'ext-auth2-container'

  credentials = {
    identifier: '',
    password: '',
    remember: false,
  };

  get settings() {
    return this.store.getSettings();
  }

  get startData() {
    return this.session.getStartData();
  }

  get changePasswordLink() {
    return { name: this.Pages.SendResetPasswordCode };
  }

  async login() {
    const result = await this.pds.login(this.credentials);
    if (result.isSuccess()) {
      this.router.changePage(result);
    } else {
      this.error = result.error;
    }
  }

  async loginWith() {
    const result = await this.pds.getLoginWithLink();
    if (result.isSuccess()) {
      this.router.changePage(result);
    } else {
      this.error = result.error;
    }
  }

  initExtAuth2() {
    const authParams: TidSDKAuthParams = {
      redirectUri: this.startData?.extauth2_redirect_uri ?? '',
      responseType: 'code',
      clientId: this.startData?.extauth2_client_id ?? '',
      state: this.startData?.extauth2_state ?? '',
    };

    const uiParams: TidSDKUIParams = {
      container: `#${this.extAuth2ContainerId}`,
      size: 'm',
      color: 'primary',
      target: '_self',
    };

    const tidSdk = new TidSDK(authParams);

    tidSdk.addButton(uiParams);
  }

  mounted() {
    if (this.startData?.extauth2_is_enable) {
      this.initExtAuth2();
    }
  }
}
